import React from 'react'
import './App.css';
import Card from './components/Card/Card';
import Header from './components/Header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <img style={{ margin: '10px', width: '200px' }}
        src="assets/10.cafe.logo.white.png"
        alt="logo"
      />
      <div style={{ margin: 50 }}>
        <section className="hero">
          <div className="hero-body">
            <h2 className="title" style={{ color: 'black' }}>10x-Café</h2>
          </div>
        </section>
      </div>
      <div style={{ margin: 50 }} >
        <div style={{ margin: 50 }}>
          <h2>What we do</h2>
        </div>
        <div className='columns'>
          <div className='column'>
            <Card title='Custom Software'>
              We can help you build your next big idea.
              Although we specialize in web and mobile applications,
              together there's nothing we can't build.
            </Card>
          </div>
          <div className='column'>
            <Card title='Digital Transformation'>
              Is your company still working with paper and/or spreadsheets?
              We can help you take the next big step.
            </Card>
          </div>
          <div className='column'>
            <Card title='Testing & QA Services'>
              Do you have an application that needs proper testing?
              How about a testing procedure that you can automate?
            </Card>
          </div>
          <div className='column'>
            <Card title='Hosting'>
              Do you need a home for your project?
              We can help you set up a domain and host your application on Linux or Windows.
            </Card>
          </div>
          <div className='column'>
            <Card title='Data Management Services'>
              Do you have large amounts of data that's getting too much to manage?
              We can with processing, storage,
              retrieval and transforming your data in to something you can use.
            </Card>
          </div>
        </div>
      </div>
      <div style={{ margin: 50 }}>
      <section className="hero">
        <div className="hero-body">
          <p className="title" style={{ color: 'black' }}>Contact Us</p>
        </div>
      </section>
      </div>

      <div style={{ margin: 50 }} >
        <div style={{ margin: 50 }}>
          <h2>Get in touch for a free planning session</h2>
        </div>
        <div className='columns'>
          <div className='column'>
            <Card title='Zandré Schalkwyk'>
              <a style={{ color: "black" }} href="mailto:zandre@10x.cafe">zandre@10x.cafe</a>
              <p style={{ color: "black" }}>082 042 3145</p>
            </Card>
          </div>
          <div className='column'>
            <Card title='Cath Schalkwyk'>
              <a style={{ color: "black" }} href="mailto:catherine@10x.cafe">catherine@10x.cafe</a>
              <p style={{ color: "black" }}>076 479 9383</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
