import React from 'react';
import './Header.css';

function Header() {
    return (
        <div className='Header'>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        10x
                    </a>
                </div>
            </nav>
        </div>
    );
}

export default Header;
