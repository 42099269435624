import React from 'react';
import './Card.css';
import { useState } from 'react';

type CardProps = {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
};
const Card = ({ children, title, subtitle}: CardProps) => {
    return (
        <div className="card Card" id=''>
            <div className="content">
                <h2 style={{ color: "black" }}>{title}</h2>
                {children}
            </div>
        </div>
    );
}

export default Card;
